.mat-step-header .mat-step-icon {
    background-color: #073351;
    color: #ffffff;
}
.mat-step-header .mat-step-icon-selected {
    background-color: #805dca;
    color: #ffffff;
}
.cont-img-sl {
    max-width: 350px;
    height: 250px;
}

.contLogo {
    width: 45%;
    margin: auto;
}
