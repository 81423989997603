aw-wizard.horizontal .wizard-steps {
    @apply flex-col;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
    background-color: #fff !important;
}

.icon-filter {
    width: 1.5rem !important;
    height: 1.25rem !important;
}
